.card {
  flex: 1 1 auto;  /* shorthand for flex-grow, flex-shrink, flex-basis */
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.tags {
  padding: 10px;
  background-color: #f8f8f8;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tag {
  background-color: #e0e0e0;
  color: #333;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 0.8em;
}