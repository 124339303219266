.gallery {
  max-width: 1260px; /* Allows for 4 columns of 300px width images with 20px gap */
  margin: 0 auto;
  padding: 20px;
}

.imageGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
}

@media (max-width: 1279px) {
  .gallery {
    max-width: 940px; /* Allows for 3 columns of 300px width images with 20px gap */
  }
}

@media (max-width: 959px) {
  .gallery {
    max-width: 620px; /* Allows for 2 columns of 300px width images with 20px gap */
  }
}

@media (max-width: 639px) {
  .gallery {
    max-width: 300px; /* Single column of 300px width images */
  }
}